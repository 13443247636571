/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "./node_modules/bootstrap/dist/css/bootstrap.css"
// import "./src/css/bootstrap.min.minty.css"
//C:\Users\pined\Documents\Workspaces\Gastby\cabbur-site\node_modules\bootstrap\dist\css\bootstrap.css